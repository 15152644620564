<template>
    <b-container class="pt-lg-5 pb-5">
        <section
            class="main-section rounded d-flex p-5 align-items-center flex-column mb-5"
        >
            <svg class="corner corner-1" width="400" height="400">
                <defs>
                    <linearGradient id="Gradient1">
                        <stop class="stop1" offset="0%"/>
                        <stop class="stop1" offset="25%"/>
                        <stop class="stop5" offset="100%"/>
                    </linearGradient>
                    <linearGradient id="Gradient2">
                        <stop class="stop5" offset="0%"/>
                        <stop class="stop1" offset="75%"/>
                        <stop class="stop1" offset="100%"/>
                    </linearGradient>
                </defs>
                <path
                    d="M70,20 h300 a10,10 0 0 1 10,10 v300 a10,0 10 0 0 1 -10"
                    fill="none"
                    stroke="url(#Gradient1)"
                    stroke-width="3"
                />
            </svg>
            <svg class="corner corner-2" width="400" height="400">
                <path
                    d="M20, 70 v300 a10,10 1 0 0 10,10 h300 a10,10 0 0 1 -10 0"
                    fill="none"
                    stroke="url(#Gradient2)"
                    stroke-width="3"
                />
            </svg>
            <h1 class="text-white text-center m-auto py-5"
                data-aos="fade-in"
                data-aos-duration="2000"
            >
                {{ t("website.aboutheadtitle") }} 
            </h1>
            <b-row class="w-100 mt-2" style="margin-bottom: -130px">
                <b-col
                    cols="12"
                    lg="4"
                    v-for="(path, i) in aboutImages"
                    :key="i"
                >
                    <div class="text-center about-images"
                        data-aos="fade-up"
                        :data-aos-duration="1000 * (i + 1)"
                    >
                        <img
                            :src="$store.getters['app/domainHost'] + '/' + path"
                            class="w-100 rounded"
                            style="max-width: 300px"
                        />
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="aboutus-section pt-5 overflow-hidden">
            <b-row class="pt-5">
                <b-col
                    cols="12"
                    lg="6"
                    class="order-0 order-lg-1 d-flex align-items-center justify-content-center"
                >
                    <div
                        class="aboutus-images-container w-100 mx-auto"
                        style="max-width: 500px"
                        data-aos="fade-left"
                        data-aos-duration="3500"
                    >
                        <div class="image-1">
                            <div class="aboutus-image-card-border rounded">
                                <div class="aboutus-image-card rounded"></div>
                            </div>
                        </div>
                        <div class="image-2">
                            <div class="aboutus-image-card-border rounded">
                                <div class="aboutus-image-card rounded">
                                    <img
                                        :src="
                                            $store.getters['app/domainHost'] +
                                                '/' + aboutDto.goalsImage
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" lg="6" class="order-1 order-lg-0">
                    <div
                        class="aboutus-description-container w-100 rounded mx-auto pt-5 pr-lg-2"
                        style="max-width: 500px"
                    >
                        <h3 class="mb-1"    
                            data-aos="fade-right"
                            data-aos-duration="3000"
                        >{{ t("website.ourgoals") }}</h3>
                        <p class="m-0"
                            data-aos="fade-right"
                            data-aos-duration="2000"
                        >{{ aboutDto.goals }}</p>
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="vision-section py-5 overflow-hidden">
            <b-row>
                <b-col
                    cols="12"
                    lg="6"
                    class="d-flex align-items-center justify-content-center"
                >
                    <div
                        class="vision-images-container w-100 mx-auto"
                        style="max-width: 500px"
                        data-aos="fade-right"
                        data-aos-duration="3500"
                    >
                        <div class="image-2">
                            <div class="vision-image-card-border rounded">
                                <div class="vision-image-card rounded">
                                    <img
                                        :src="
                                            $store.getters['app/domainHost'] +
                                                '/' +
                                                aboutDto.visionImage
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" lg="6">
                    <div
                        class="vision-description-container text-center text-lg-left w-100 rounded mx-auto py-5"
                        style="max-width: 500px"
                    >
                        <h3 class="mb-1"
                            data-aos="fade-left"
                            data-aos-duration="3000"
                        >{{ t("website.ourvision") }}</h3>
                        <p class="m-0"
                            data-aos="fade-left"
                            data-aos-duration="2000"
                        >
                            {{ aboutDto.vision }}
                        </p>
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="employees-section py-5 conatiner">
            <h2 class="my-3 text-center text-light">{{t("website.employees")}}</h2>
            <b-row class="text-center">
                <b-col
                    cols="12"
                    lg="4"
                    class="mx-auto py-5"
                    v-for="(employee, i) in employees"
                    :key="i"
                >
                    <div class="w-100 employee-card"
                        data-aos="fade-up"
                        :data-aos-duration="1000 * ((i % 3) + 1)"
                    >
                        <div class="gradient-background">
                            <div class="inner-background">
                                <img
                                    :src="
                                        employee.imageDto.path
                                            ? $store.getters['app/domainHost'] +
                                              '/' +
                                              employee.imageDto.path
                                            : '/media/images/site-images/employee.jpg'
                                    "
                                    class="img-fluid employee-img"
                                />
                               <div class="d-flex flex-column">
                                    <h5 class="employee-name p-0 mt-1">{{ employee.name }}</h5>
                                    <h5 class="employee-name p-0">{{ employee.positionName }}</h5>
                               </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="clients-section py-5 conatiner">
            <h2 class="my-3 text-center text-light">{{t("website.clients")}}</h2>
            <b-row class="text-center">
                <b-col
                    cols="12"
                    lg="3"
                    class="mb-2"
                    v-for="(client, i) in clients"
                    :key="i"
                >
                    <div class="client-card"
                        data-aos="fade-up"
                        :data-aos-duration="750 * ((i % 4) + 1)"
                        v-b-popover.hover.top variant="flat-secondary" :title="client.name"
                    >
                        <img
                            :src="
                                $store.getters['app/domainHost'] +
                                    '/' +
                                    client.image
                            "
                            class="h-100"
                        />
                    </div>
                </b-col>
            </b-row>
        </section>
    </b-container>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapActions, mapState } from "vuex";
import { handleLangChange } from '@/libs/event-bus';
export default {
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    created() {
        this.getWebsiteAbout();
        handleLangChange(this.getWebsiteAbout);
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.aboutus"),
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: this.$t("website.aboutheadtitle") + ' ' + this.$t("website.bucket") + ' ' + this.$t("website.metal")
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    computed: {
        ...mapState({
            aboutImages: state => state.global.aboutImages,
            aboutDto: state => state.global.aboutDto,
            employees: state => state.global.employees,
            clients: state => state.global.clients
        })
    },
    methods: {
        ...mapActions(["getWebsiteAbout"])
    }
};
</script>

<style scoped lang="scss">
.main-section {
    min-height: 400px;
    margin: auto;
    background: url("/media/images/site-images/section-bg.png") center center
        no-repeat;
    background-size: cover;
    position: relative;
    .corner {
        position: absolute;
        &.corner-1 {
            top: 0;
            left: 0;
        }
        &.corner-2 {
            bottom: 0;
            right: 0;
        }
    }
    h1 {
        line-height: 2;
    }
    .about-images {
        margin: auto;
        height: 300px;
        max-width: 300px;
        margin-bottom: 1rem;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
.aboutus-section {
    color: #fff;
    .aboutus-images-container {
        height: 400px;
        position: relative;
        .image-1,
        .image-2 {
            position: absolute;
            height: 100%;
            width: 100%;
            .aboutus-image-card-border {
                height: 100%;
                width: 100%;
                padding-top: 3px;
                padding-right: 2px;
                .aboutus-image-card {
                    height: 100%;
                    width: 100%;
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .image-1 {
            top: 0;
            .aboutus-image-card-border {
                border: solid 1px #fff;
            }
        }
        .image-2 {
            top: 26px;
            right: 26px;
        }
    }
    .aboutus-description-container {
        h3 {
            color: #fff;
            margin: 0;
        }
        p {
            padding: 0 0 2em;
            text-align: justify;
        }
    }
    @media screen and (max-width: 992px) {
        .aboutus-images-container {
            .image-1 {
                .aboutus-image-card-border {
                    border: none;
                }
            }
            .image-2 {
                top: 0;
                right: 0;
            }
        }
    }
}
.vision-section {
    color: #fff;
    .vision-images-container {
        height: 400px;
        position: relative;
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: absolute;
            height: 100%;
            width: 100%;
            .vision-image-card-border {
                transform-origin: left center;
                transform: scale(0.9) rotate3d(-22, 6, 0, 10deg)
                    skew(8deg, 0deg);
                height: 100%;
                width: 100%;
                padding-top: 3px;
                padding-right: 2px;
                .vision-image-card {
                    height: 100%;
                    width: 100%;
                }
                .vision-section .vision-images-container .image-2 img{
                    position:absolute
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .vision-description-container {
        h3 {
            color: #fff;
            margin: 0;
        }
        p {
            padding: 0 0 2em;
            text-align: justify;
        }
    }
    @media screen and (max-width: 992px) {
        .vision-images-container {
            .image-2 {
                transform: translateY(0);
                top: 0;
                .vision-image-card-border {
                    transform: rotate(0deg) !important;
                }
            }
        }
    }
}
.employee-card {
    max-width: 275px;
    height: 222px;
    position: relative;
    border-radius: 10px;
    .gradient-background {
        width: 100%;
        height: 100%;
        background: linear-gradient(-37deg, #fff, #595959, #fff, #595959);
        border-radius: 10px;
        padding: 5px;
        transition: height 0.5s;
        &:hover {
            height: 115%;
            .employee-name {
                opacity: 1;
            }
        }
        .inner-background {
            height: 100%;
            width: 100%;
            background: #47494a;
            border-radius: 6px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            h5 {
                opacity: 0;
                color: white;
                padding: 10px 0;
                transition: height 1.5s;
            }
            .employee-img {
                border-radius: 10px;
                position: absolute;
                left: 26px;
                bottom: 26px;
                width: 275px;
                height: 227px;
                object-fit: cover;
            }
        }
    }
}
.client-card {
    height: 100px;
    border: solid 1px #fff;
    border-radius: 4px;
    padding: 1rem;
}

[dir="ltr"] {
    .main-section {
        .corner {
            &.corner-1 {
                right: 0;
                left: auto;
            }
            &.corner-2 {
                left: 0;
                right: auto;
            }
        }
    }
    .aboutus-section {
        .aboutus-images-container {
            .image-2 {
                left: auto;
                right: 26px;
            }
        }
        @media screen and (max-width: 992px) {
            .aboutus-images-container {
                .image-2 {
                    right: auto;
                    left: 0;
                }
            }
        }
    }
    .vision-section {
        .vision-images-container {
            .image-2 {
                .vision-image-card-border {
                    transform: scale(0.9) rotate3d(-22, 6, 0, -10deg)
                        skew(8deg, 0deg);
                }
            }
        }
    }
    .employee-card {
        max-width: 275px;
        height: 222px;
        position: relative;
        border-radius: 10px;
        .gradient-background {
            width: 100%;
            height: 100%;
            background: linear-gradient(-37deg, #fff, #595959, #fff, #595959);
            border-radius: 10px;
            padding: 5px;
            transition: height 0.5s;
            &:hover {
                height: 115%;
                .employee-name {
                    opacity: 1;
                }
            }
            .inner-background {
                height: 100%;
                width: 100%;
                background: #47494a;
                border-radius: 6px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                h3 {
                    opacity: 0;
                    color: white;
                    padding: 10px 0;
                    transition: height 1.5s;
                }
                .employee-img {
                    border-radius: 10px;
                    position: absolute;
                    left: 26px;
                    bottom: 26px;
                    width: 275px;
                    height: 227px;
                    object-fit: cover;
                }
            }
        }
    }
}
</style>
